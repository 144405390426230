import { createSlice } from "@reduxjs/toolkit";
import { FOLDERNAMES } from "app/variables";
import {cloneDeep} from "lodash"
const initialState = {
  inboxList: [],
  inboxFolder:[],
  selectedFolder: null,
  currentInboxList: [],
  isInboxListLoading: false,
  isInboxFolderLoading: false,
  selectedMessage: null,
  emailAddressBook: JSON.parse(sessionStorage.getItem('addressBook__' + sessionStorage.getItem('companyId')))??[],
  openedFolders: [],
  contractInfoForCompany: [],
  inputValForEmailSearch: '',
  searchedMailList: [],
  companyVariable: JSON.parse(sessionStorage.getItem('companyVariable' + sessionStorage.getItem('companyId')))??[],
  loadedMailFolders: [],
  mailBoxSettings: null,
  contactAddressBook: JSON.parse(sessionStorage.getItem('contactAddressBook__' + sessionStorage.getItem('companyId')))??[],

};

const DEFAULT_FOLDERS = {
  INBOX : {NAME: FOLDERNAMES.INBOX, POSIITION: 0},
  SENT: {NAME: FOLDERNAMES.SENT, POSIITION: 1},
  DRAFT: {NAME: FOLDERNAMES.DRAFT, POSIITION: 2},
  JUNK: {NAME: FOLDERNAMES.JUNK, POSIITION: 3},
  DELETE: {NAME: FOLDERNAMES.DELETED, POSIITION: 4},
  OUTBOX: {NAME: FOLDERNAMES.OUTBOX, POSIITION: 5},
  CONSERVATIONHISTORY: {NAME: FOLDERNAMES.CONSERVATIONHISTORY, POSIITION: 6}
}

const findAndUpdateClick = (folderList, displayName) => {
  let isItemFound = folderList.findIndex((item) => item.displayName === displayName);
  if (isItemFound !== -1) {
    folderList[isItemFound].isOpen = !folderList[isItemFound].isOpen;
    return folderList;
  }
  else {
    return folderList.map((item) => {
      if (item.childFolderCount > 0 && item.childFolders) {
        let arr = findAndUpdateClick(item.childFolders, displayName)
        return {...item, childFolders:arr }
      }
      else {
        return item;
      }
    });
  }
}

const groupbyEmailsByConversationId = (inboxList) => {
  let conversationIds = new Set();
  let indicesToDelete = [];
  try {
    for(let i = 0; i < inboxList.length; i++) {
      const conversationId = inboxList[i].conversationId;
      if (conversationIds.has(conversationId)) {
        indicesToDelete.push(i);
        let firstEleIndx = inboxList.findIndex((item) => item.conversationId === conversationId);
        inboxList[firstEleIndx] = {...inboxList[firstEleIndx], conversation: inboxList[firstEleIndx].conversation == null ? 
          [inboxList[i]] : inboxList[firstEleIndx].conversation.push(inboxList[i]) } 
      }
      else {
        conversationIds.add(conversationId);
      }
    }
    inboxList = inboxList.filter((item, index) => !indicesToDelete.includes(index));
  }
  catch(err) {
    console.log(err)
  }
  return inboxList 
};

const getContractInfoLocally = (email, contractInfoArr) => {
  if (email.isDraft) {
    return null;
  }
  let mail_from = email.from.emailAddress.address;
    let emailAddress;
    if (mail_from===sessionStorage.getItem('companyEmail')){
      if (email.toRecipients.length >0) {
        emailAddress = email.toRecipients[0].emailAddress.address;
      }
      else if(email.ccRecipients.length>0) {
        emailAddress = email.ccRecipients[0].emailAddress.address;
      }
      else if(email.bccRecipients.length>0) {
        emailAddress = email.bccRecipients[0].emailAddress.address;
      }
      else {
        return null;
      }
    }
    else {
      emailAddress = email.from.emailAddress.address;
    }
    let propertyInfo = contractInfoArr.filter((contractInfo) => contractInfo.Email === emailAddress);
    if (propertyInfo.length===0) {
      return null;
    }
    else if(propertyInfo.length===1) {
      return {Unit: propertyInfo[0].Unit, Role: propertyInfo[0].Role}
    }
    else {
      let ownerRole = propertyInfo.filter((item) => item.Role === 'Owner');
      if (ownerRole.length > 0) {
        if (ownerRole.length===1) {
          return {Role: ownerRole[0].Role, Unit: ownerRole[0].Unit}
        }
        else {
          return {Role: ownerRole[0].Role, Unit: ownerRole.map((unit) => unit['Unit']??'').join(' ,')}
        } 
      }
    }
}

const getAddressFilteredList = (addressBook) => {
  if (!addressBook) {
    return;
  }
  const emailArr = [];
  let filteredArr = []

  addressBook.map((address) => {
    if (emailArr.includes(address.email)) {
      //getting index of address in filtered arr
      let _indx = filteredArr.findIndex((filteredAdd) => filteredAdd.email===address.email);
      if (_indx !== -1) {
        //checking if unit if present in unit arr
        let checkIfUnitNotPresent = filteredArr[_indx].unitRoleArr.filter((unitArrObj) => unitArrObj.unit === address.unit);

        //if present and its previous owner then replacing it with owner
        if (checkIfUnitNotPresent.length > 0 && checkIfUnitNotPresent[0].role==="Previous owner" && address.role==="Owner") {
          // getting index for unit so we can replace it owner from previous owner
          let preOwnerObjIndx =  filteredArr[_indx].unitRoleArr.findIndex((unitArrObj) => unitArrObj.unit === address.unit);
          filteredArr[_indx].unitRoleArr[preOwnerObjIndx].role = "Owner"
        }
        else if (checkIfUnitNotPresent.length > 0 && checkIfUnitNotPresent[0].role==="Previous Renter" && address.role==="Renter") {
          // getting index for unit so we can replace it renter from previous renter
          let preOwnerObjIndx =  filteredArr[_indx].unitRoleArr.findIndex((unitArrObj) => unitArrObj.unit === address.unit);
          filteredArr[_indx].unitRoleArr[preOwnerObjIndx].role = "Renter"
        }
        else if (checkIfUnitNotPresent.length===0) {
          filteredArr[_indx].unitRoleArr = [...filteredArr[_indx].unitRoleArr, {unit: address.unit, role: address.role}];
        }
      }
    }
    else {
      emailArr.push(address.email);
      filteredArr.push({...address, unitRoleArr: [{unit:address.unit, role: address.role}]});
    }
  });
  return filteredArr;
}



const getModifiedInboxList = (inboxList, contractInfoArr) => {
  return inboxList.map((email) => {
    return {...email, contractInfo: getContractInfoLocally(email, contractInfoArr)}
  });
}

const getUnqiueList = (newList, oldList) => {
  const idsInList2 = new Set(oldList.map(item => item.id));
  let uniqueObjects = newList.filter(item => !idsInList2.has(item.id));
  return [...uniqueObjects, ...oldList];
}

export const getUnqiueListForFolder = (list) => {
  const seenIds = new Set();
  const uniqueObjects = list.filter(item => {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id);
      return true;
    }
    return false;
  });
  return uniqueObjects;
};

export const getUnqiueListBySubjectAndSendDate = (list) => {
  const seen = new Set();
  const uniqueData = list.filter(item => {
    const identifier = item.subject + item.createdDateTime;
    if (seen.has(identifier)) {
      return false;
    } else {
      seen.add(identifier);
      return true;
    }
  });
  return uniqueData;
}


const getSortedListForFolder = (list) => {
  let sortedList = list.sort((objA, objB) => new Date(objB.createdDateTime) - new Date(objA.createdDateTime));
  return sortedList
}

const getLocalSearchResult = (list, folderId, searchText) => {
  let folderFilteredList = list.filter((mail) => mail.parentFolderId === folderId);
  let filteredList = folderFilteredList.filter((mail) => mail.subject?.includes(searchText) || mail.from?.emailAddress.address.includes(searchText) || mail.from?.emailAddress.name.includes(searchText));
  return filteredList;
}

const updateTotalItemCount = (folderList, folderId, count) => {
  for (let i = 0; i < folderList.length; i++) {
    if (folderList[i].id === folderId) {
      folderList[i].totalItemCount = folderList[i].totalItemCount + count;
      return folderList;
    }
    if (Array.isArray(folderList[i].childFolders)) {
      if (folderList[i].childFolders.length > 0) {
        updateTotalItemCount(folderList[i].childFolders, folderId, count);
      }
      else {
        return;
      }
    }
  }
  return folderList;
};

const _updateReadCountAndTotalItemHelper = (folders, folderId, TYPE, isRead) => {
  if (!folders) return;

  for (let i = 0; i < folders.length; i++) {
    let folder = folders[i];
    if (folder.id === folderId) {
      if (TYPE === 'TARGET_FOLDER') {
        folders[i].totalItemCount = folders[i].totalItemCount + 1;
        if (!isRead) {
          folders[i].unreadItemCount = folders[i].unreadItemCount + 1;
        }
      } else {
        folders[i].totalItemCount = folders[i].totalItemCount - 1;
        if (!isRead) {
          folders[i].unreadItemCount = folders[i].unreadItemCount - 1;
        }
      }

      return folders;
    } else if (Array.isArray(folder.childFolders)) {
      if (folder.childFolders.length > 0) {
        let updatedFolders = _updateReadCountAndTotalItemHelper(folder.childFolders, folderId, TYPE, isRead);
        if (updatedFolders) {
          folders[i].childFolders = updatedFolders;
        }
      }
    }
  }
  return folders;
};



const updateReadCountAndTotalItem = (folderList, currentFolderId, targetFolderId, isRead) => {
  let folderListForCurrentFolder = _updateReadCountAndTotalItemHelper(folderList, currentFolderId, 'CURRENT_FOLDER', isRead);
  let folderListForTargetFolder = _updateReadCountAndTotalItemHelper(folderListForCurrentFolder, targetFolderId, 'TARGET_FOLDER', isRead);
  return folderListForTargetFolder;
};

const prepareContactAddressBook = ({data, calledFROM}) => {
  if (!data) {
    return [];
  }
  if (calledFROM === "UI") {
    return data;
  }
  return data.map((contact) => {
    return {email: contact.emailAddresses[0].address, name: contact.displayName}
  });
}

const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    inboxListAction: (state, action) => {
      let _clonedInboxList = [...action.payload.inboxList];
      try {
        let _contractInfoArr = cloneDeep(state.contractInfoForCompany);
        let modifiedArr = getModifiedInboxList(_clonedInboxList, _contractInfoArr);
        let modifiedList = getUnqiueList(modifiedArr, [...state.inboxList]);
        state.inboxList = [...modifiedList];
      }
      catch (err) {
        console.log(err);
        state.inboxList = _clonedInboxList;
      }
      
      //let inboxList = cloneDeep(action.payload.inboxList);
      //state.inboxList = inboxList
    },
    paginatedInboxListAction: (state, action) => {
      let _clonedInboxList = [...action.payload.inboxList];
      try {
        let _contractInfoArr = cloneDeep(state.contractInfoForCompany);
        let modifiedArr = getModifiedInboxList(_clonedInboxList, _contractInfoArr);
        state.inboxList = [...state.inboxList, ...modifiedArr];
      }
      catch (err) {
        console.log(err);
        state.inboxList = [...state.inboxList, ..._clonedInboxList];
      }
    },
    inboxFolderAction: (state, action) => {
      state.inboxFolder = action.payload.inboxFolder.filter((folder) => !FOLDERNAMES.ARCHIVE.includes(folder.displayName));
      try {
        for(var key in DEFAULT_FOLDERS) {
          let arr = [...state.inboxFolder];
          let inboxFolderIndex = arr.findIndex((folder) => {
            return DEFAULT_FOLDERS[key].NAME.includes(folder.displayName);
          });
          const element = arr.splice(inboxFolderIndex, 1)[0];
          arr.splice(DEFAULT_FOLDERS[key].POSIITION, 0, element);
          state.inboxFolder = [...arr];
        }
        
      }
      catch(err) {
        console.log(err)
      }
      
    },
    inboxSelectedFolderAction: (state, action) => {
      state.selectedFolder = action.payload
    },
    currentInboxListAction: (state, action) => {
      let filteredList = state.inboxList?.filter((item) => item.parentFolderId === state.selectedFolder?.id);
      let _clonedFileredList = cloneDeep(filteredList);
      let uniqueList = getUnqiueListForFolder(_clonedFileredList);
      let uniqueListBySubjectAndCreatedTime = getUnqiueListBySubjectAndSendDate(uniqueList);
      let sortedList = getSortedListForFolder(uniqueListBySubjectAndCreatedTime);
      state.currentInboxList = [...sortedList];
    },
    handleOnClickOpenAction: (state, action) => {
      let folderList = cloneDeep(state.inboxFolder);
      let arr = findAndUpdateClick(folderList, action.payload.displayName);
      state.inboxFolder = [...arr];
    },
    changeMessageReadStatusAction: (state, action) => {
      try {
        let index = state.inboxList.findIndex((item) => item.id === action.payload);
        let inboxList = cloneDeep(state.inboxList);
        inboxList[index].isRead = !inboxList[index].isRead;
        state.inboxList = [...inboxList];
      }
      catch (err) {
        console.log(err)
      }
    },
    changeUnReadCountAction: (state, action) => {
      try {
        let index = state.inboxFolder.findIndex((item) => item.id === action.payload.folderId);
        let folderList = cloneDeep(state.inboxFolder);
        if (action.payload.readType === "READ") {
          folderList[index].unreadItemCount = folderList[index].unreadItemCount - 1;
        }
        else if (action.payload.readType === "UNREAD") {
          folderList[index].unreadItemCount = folderList[index].unreadItemCount + 1;
        }
        state.inboxFolder = [...folderList];
      }
      catch (err) {
        console.log(err)
      }
    },
    changeUnReadCountForSelectedFolderAction:(state, action) => {
      try {
        let clonedObject = cloneDeep(action.payload.selectedFolder);
        if (action.payload.readType === "READ") {
          clonedObject.unreadItemCount = clonedObject.unreadItemCount - 1;
        }
        else if (action.payload.readType === "UNREAD") {
          clonedObject.unreadItemCount = clonedObject.unreadItemCount + 1;
        }
        state.selectedFolder = {...clonedObject};
      }
      catch(err) {
        console.log(err);
      }
    },
    deleteMailFromFrontendAction: (state, action) => {
      try {
        let cloneList = cloneDeep(state.inboxList);
        let filteredList = cloneList.filter(item => item.id !== action.payload);
        state.inboxList = [...filteredList];
      }
      catch(err) {
        console.log(err)
      }
    },
    moveMailAction: (state, action) => {
      try {
        let index = state.inboxList.findIndex((item) => item.id === action.payload.messageId);
        let inboxList = cloneDeep(state.inboxList);

        let _clonedFolderList = cloneDeep(state.inboxFolder);
      
        // capturing current folder id and target folder id
        let currentFolderId = inboxList[index].parentFolderId;
        let targetFolderId = action.payload.folderId;

        //updaing parent folder id
        inboxList[index].parentFolderId = action.payload.folderId;
        state.inboxList = [...inboxList];

        //declaring current folder object
        /*
        let currentFolderIndex = _clonedFolderList.findIndex((item) => item.id===inboxList[index].parentFolderId);

        let targetFolderIndex = _clonedFolderList.findIndex((item) => item.id===action.payload.folderId);
        //checking if mail is read or unread
        if (inboxList[index].isRead === false) {
          _clonedFolderList[targetFolderIndex].unreadItemCount += 1;
          _clonedFolderList[currentFolderIndex].unreadItemCount -= 1;
        }
        //changing total count of mail in folder
        if (targetFolderIndex !== -1) {
          _clonedFolderList[targetFolderIndex].totalItemCount = _clonedFolderList[targetFolderIndex].totalItemCount + 1;
        }
          */
        let updatedFolderList = updateReadCountAndTotalItem(_clonedFolderList, currentFolderId, targetFolderId, inboxList[index].isRead);
        state.inboxFolder = [...updatedFolderList];
      }
      catch (err) {
        console.log(err)
      }
    },
    bulkMailDeleteAction: (state, action) => {
      try {
        let _clonedList = cloneDeep(state.inboxList);
        let _filteredList = _clonedList.filter((item) => !action.payload.includes(item.id));
        state.inboxList = [..._filteredList];
      }
      catch (err) {
        console.log(err)
      }
    },
    bulkMailMoveAction: (state, action) => {
      try {
        let _clonedList = cloneDeep(state.inboxList);
        let _filteredList = _clonedList.filter((item) => !action.payload.bulkArr.includes(item.id));
        let _mailToMoveList = _clonedList.filter((item) => action.payload.bulkArr.includes(item.id));
        _mailToMoveList = _mailToMoveList.map((mail) => { return { ...mail , parentFolderId : action.payload.folderId}});
        state.inboxList = [..._filteredList, ..._mailToMoveList];
        let _clonedFolderList = cloneDeep(state.inboxFolder);
        
        let updatedFolderList = updateTotalItemCount(_clonedFolderList, action.payload.folderId, _mailToMoveList.length);

        state.inboxFolder = [...updatedFolderList];
      }
      catch (err) {
        console.log(err)
      }
    },
    setInboxListLoadingAction: (state, action) => {
      state.isInboxListLoading = action.payload;
    },
    setInboxFolderLoadingAction: (state, action) => {
      state.isInboxFolderLoading = action.payload
    },
    setSelectedMessageAction: (state, action) => {
      state.selectedMessage = action.payload;
    },
    setEmailAddressBookAction: (state, action) => {
      let filteredList = getAddressFilteredList([...action.payload]);
      state.emailAddressBook = filteredList;
      localStorage.setItem('addressBook__' + sessionStorage.getItem('companyId'), JSON.stringify(action.payload));
    },
    handleOpenedFolderAction: (state, action) => {
      let openedFolders = state.openedFolders;
      if (!openedFolders.includes(action.payload)) {
        state.openedFolders = [...openedFolders, action.payload];
      }
      else {
        openedFolders = openedFolders.filter((folderId) => folderId !== action.payload);
        state.openedFolders = [...openedFolders];
      }
    },
    setContractInfoForCompanyAction: (state, action) => {
      state.contractInfoForCompany = action.payload;
    },
    setInputValForEmailSearchAction: (state, action) => {
      //console.log('search text', {...state.selectedFolder})
      state.inputValForEmailSearch = action.payload;
    },
    setSearchResultAction: (state, action) => {
      let searchResult = action.payload.data;
      let localSearchResult = getLocalSearchResult([...cloneDeep(state.inboxList)], action.payload.folderId, action.payload.searchText);
      let mergedList = [...localSearchResult, ...searchResult];
      let unqiueList = getUnqiueListForFolder(mergedList);
      state.searchedMailList = getSortedListForFolder(unqiueList);
    },
    setCompanyVariableAction: (state, action) => {
      state.companyVariable = action.payload;
      sessionStorage.setItem("companyVariable", action.payload);
    },
    setLoadedMailFromFolderIdAction: (state, action) => {
      let loadedMailFolders = [...state.loadedMailFolders];
      state.loadedMailFolders = [...loadedMailFolders, action.payload]
    },
    updateMailBoxSettingsAction: (state, action) => {
      state.mailBoxSettings = action.payload;
    },
    unFlaggedMailAction: (state, action) => {
      let msg = action.payload;
      let _clonedInboxList = cloneDeep(state.inboxList);
      let _msgIndx = _clonedInboxList.findIndex((item) => item.id===msg.id);
      if(_msgIndx !== -1) {
        _clonedInboxList[_msgIndx].flag.flagStatus = 0;
      }
      //changing status in main list first
      state.inboxList = [..._clonedInboxList];
    },
    FlagMailAction: (state, action) => {
      let msg = action.payload;
      let _clonedInboxList = cloneDeep(state.inboxList);
      let _msgIndx = _clonedInboxList.findIndex((item) => item.id===msg.id);
        //add flagged status if not flagged
      if(_msgIndx !== -1) {
        _clonedInboxList[_msgIndx].flag.flagStatus = 2;
      }
      //changing status in main list first
      state.inboxList = [..._clonedInboxList];
    },
    CompleteFlagMailAction: (state, action) => {
      let msg = action.payload;
      let _clonedInboxList = cloneDeep(state.inboxList);
      let _msgIndx = _clonedInboxList.findIndex((item) => item.id===msg.id);
        //add flagged status if not flagged
      if(_msgIndx !== -1) {
        _clonedInboxList[_msgIndx].flag.flagStatus = 1;
      }
      //changing status in main list first
      state.inboxList = [..._clonedInboxList];
    },
    setContactEmailAddressBookAction: (state, action) => {
      let contactAddressBook = prepareContactAddressBook(action.payload);
      state.contactAddressBook = contactAddressBook;
      localStorage.setItem('contactAddressBook__' + sessionStorage.getItem('companyId'), JSON.stringify(contactAddressBook));
    },
  },
});

export const {
    inboxListAction,
    paginatedInboxListAction,
    inboxFolderAction,
    inboxSelectedFolderAction,
    currentInboxListAction,
    handleOnClickOpenAction,
    changeMessageReadStatusAction,
    changeUnReadCountForSelectedFolderAction,
    changeUnReadCountAction,
    deleteMailFromFrontendAction,
    moveMailAction,
    bulkMailDeleteAction,
    bulkMailMoveAction,
    setInboxListLoadingAction,
    setInboxFolderLoadingAction,
    setSelectedMessageAction,
    setEmailAddressBookAction,
    handleOpenedFolderAction,
    setContractInfoForCompanyAction,
    setInputValForEmailSearchAction,
    setSearchResultAction,
    setCompanyVariableAction,
    setLoadedMailFromFolderIdAction,
    updateMailBoxSettingsAction,
    unFlaggedMailAction,
    FlagMailAction,
    CompleteFlagMailAction,
    setContactEmailAddressBookAction
} = inboxSlice.actions;
export default inboxSlice.reducer;
